import { NextPage } from 'next'
import { useRouter } from 'next/dist/client/router'
import { SVGLogoError } from '@shared/SVG'
import css from 'styled-jsx/css'

const Page404: NextPage = () => {
  const router = useRouter()

  return (
    <>
      <style jsx>{pageStyle}</style>
      <style jsx>{`
        .page-container {
          max-width: 450px;
          line-height: 32px;
          font-weight: 500;
          font-size: 16px;
        }
      `}</style>
      <div className="shophelp-error is-flex is-align-items-center is-justify-content-center">
        <div className="page-container has-text-centered">
          <SVGLogoError className="shophelp-error__logo" />
          <p>Something went wrong at our end.</p>
          <p>Don’t worry, it’s not you - it’s us</p>
          <p>Sorry about that.</p>
          <button
            type="button"
            className="button is-primary is-fullwidth mt-7 mb-4"
            onClick={() => router.push('/')}
          >
            Back to home page
          </button>
          <a className="ís-link" href="mailto:support@shophelo.org">
            Contact us via email
          </a>
        </div>
      </div>
    </>
  )
}

export default Page404

const pageStyle = css.global`
  .shophelp-error {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: var(--white-100);
  }
`
